import "./styles.scss";

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import classnames from "classnames";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

import Input from "components/Input";
import Checkbox from "components/Checkbox";
import Button from "components/Button";

import contactFormSchema from "libs/validations/contactFormSchema";

import SendIcon from "icons/send.svg";

const mainClass = "form";

const FormContent = ({ search, variant, direction }) => {
	const isBrowser = typeof window !== "undefined";
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [sent, setSent] = useState(false);
	const [error, setError] = useState(false);

	const initial_values = {
		first_name: "",
		email: "",
		phone: "",
		message: "",
		legal1: false,
		legal2: false,
		legal3: false,
	};

	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(false);
			}, 3000);
		}
	}, [error]);

	const onSubmit = async (
		{ first_name, email, phone, message, legal1, legal2, legal3 },
		setSubmitting
	) => {
		setSent(true);

		if (!executeRecaptcha) {
			setError(true);
			setSent(false);
		} else {
			let formData = new FormData();

			const utm_source = new URLSearchParams(search).get("utm_source");
			const utm_medium = new URLSearchParams(search).get("utm_medium");
			const utm_campaign = new URLSearchParams(search).get(
				"utm_campaign"
			);
			const utm_term = new URLSearchParams(search).get("utm_term");

			formData.set("first_name", first_name);
			formData.set("email", email);
			formData.set("phone", phone);
			formData.set("message", message);
			formData.set("investment", "Sea Shell");
			formData.set("utm_source", utm_source || "");
			formData.set("utm_medium", utm_medium || "");
			formData.set("utm_campaign", utm_campaign || "");
			formData.set("utm_term", utm_term || "");
			formData.set("legal1", legal1);
			formData.set("legal2", legal2);
			formData.set("legal3", legal3);

			const token = await executeRecaptcha("contact_form");
			formData.set("_wpcf7_recaptcha_response", token);

			axios({
				method: "post",
				url: "https://wp.seashellapartments.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((data) => {
					if (data?.data?.status === "spam") {
						setError(true);
						setSent(false);
					}
					isBrowser &&
						window.dataLayer.push({
							event: "FormCompleted",
						});
					setSubmitting(false);
				})
				.catch(() => {
					setSent(false);
					setSubmitting(false);
				});
		}
	};

	return (
		<div
			className={classnames(mainClass, {
				[`${mainClass}--${variant}`]: !!variant,
				[`${mainClass}--${direction}`]: !!direction,
				[`${mainClass}--sent`]: !!sent || error,
			})}
		>
			<Formik
				initialValues={initial_values}
				validationSchema={contactFormSchema}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={(values, { setSubmitting }) => {
					onSubmit(values, setSubmitting);
				}}
			>
				{({ isSubmitting }) => (
					<Form className={`${mainClass}__content`}>
						<div className={`${mainClass}__left`}>
							<Input
								name="first_name"
								placeholder="Imię"
								disabled={!!sent || error}
							/>
							<Input
								name="email"
								placeholder="E-mail *"
								disabled={!!sent || error}
							/>
							<Input
								name="phone"
								placeholder="Telefon *"
								disabled={!!sent || error}
							/>
							<Input
								name="message"
								placeholder="Wiadomość"
								disabled={!!sent || error}
								multiline
							/>
						</div>
						<div className={`${mainClass}__right`}>
							<Checkbox
								name="legal1"
								disabled={!!sent || error}
								label="* Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu i zakresie koniecznym do realizacji zgłoszenia."
							/>
							<Checkbox
								name="legal2"
								disabled={!!sent || error}
								label="* Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu przesyłania mi ofert handlowych drogą elektroniczną."
							/>
							<Checkbox
								name="legal3"
								disabled={!!sent || error}
								label={
									<>
										* Wyrażam zgodę na przetwarzanie moich
										danych osobowych zawartych w niniejszym
										formularzu kontaktowym w celu kontaktu
										telefonicznego ze strony przedstawicieli
										spółki w sprawach związanych z ofertą
										handlową.
										<br />
										<br />
										Administratorem danych jest Oak Property
										Sp. z o.o. z siedzibą w Warszawie
										(00-411), ul. Wioślarska 8. Podanie
										danych jest dobrowolne. Więcej
										informacji o ochronie danych osobowych
										znajduje się w{" "}
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="/polityka-prywatnosci"
										>
											Polityce Prywatności
										</a>
										.
									</>
								}
							/>
							<div className={`${mainClass}__submit`}>
								<Button
									type="submit"
									disabled={!!sent || !!isSubmitting || error}
									variant={
										variant === "light" ? "green" : null
									}
									icon={
										!!isSubmitting ||
										!!sent ||
										error ? null : (
											<SendIcon />
										)
									}
								>
									{!!isSubmitting
										? "Wysyłanie..."
										: error
										? "Błąd"
										: !!sent
										? "Wysłano"
										: "Wyślij"}
								</Button>
								{error && (
									<div className={`${mainClass}__error`}>
										<p>
											Wystąpił błąd. Prosimy spróbować
											ponownie.
										</p>
									</div>
								)}
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default FormContent;
